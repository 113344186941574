import React from 'react';
import LOPDForm from '../components/Login/LOPD/LOPDForm';
import { AppRoute, routes_definition } from '../constants/routes';
import Ayuda from '../routes/Ayuda/Help';
import CommercialCommunications from './ CommercialCommunications/CommercialCommunications';
import page404 from './404/page404';
import AcademicCenterList from './Academy/AcademicCenter/AcademicCenterList';
import AcademyLayout from './Academy/AcademyLayout/AcademyLayout';
import AccountingMenu from './Accounting/AccountingMenu';
import Configuration from './Accounting/Configuration/Configuration';
import CostCenterConfig from './Accounting/CostCenter/CostCenterConfig';
import SwitchCostCenter from './Accounting/CostCenter/SwitchCostCenter';
import ExpenseAccounts from './Accounting/ExpenseAccounts/ExpenseAccounts';
import ExpenseAccountsConfig from './Accounting/ExpenseAccounts/ExpenseAccountsConfig';
import InvoiceConfig from './Accounting/Invoices/InvoiceConfig';
import InvoicesPayment from './Accounting/Invoices/InvoicesPayment';
import InvoicesTable from './Accounting/Invoices/InvoicesTable';
import MassiveUpload from './Accounting/MassiveUpload/MassiveUpload';
import PendingInvoices from './Accounting/PendingInvoices/PendingInvoices';
import ProjectPayments from './Accounting/ProjectsPayment/ProjectPayments';
import EntitiesScholarshipPayments from './Accounting/ScholarshipsPayment/EntitiesScholarshipPayments/EntitiesScholarshipPayments';
import SendToSAGE from './Accounting/SendToSAGE/SendToSAGE';
import Assigment from './ConfigCustomField/KPIS/Assigment';
import AssignmentType from './ConfigCustomField/KPIS/AssignmentType';
import KPIS from './ConfigCustomField/KPIS/KPIS';
import { default as KPISConfig, default as KPISConfigCustom } from './ConfigCustomField/KPIS/KPISConfig';
import KPISTypes from './ConfigCustomField/KPIS/KPISTypes';
import AboutUsEdition from './Configuration/AboutUs/AboutUsEdition';
import AboutUsResources from './Configuration/AboutUs/AboutUsResources';
import AboutsUs from './Configuration/AboutUs/AboutsUs';
import AreasConfig from './Configuration/Areas/AreasConfig';
import ConfigEnableScholarship from './Configuration/EnableScholarship/EnableScholarship';
import FollowupDates from './Configuration/FollowupDates/FollowupDates';
import FormsAssistantInternalProjectTemplate from './Configuration/InternalProjects/Templates/InternalProjectTemplateEdition/GeneralForms/FormsAssistant/FormsAssistant';
import InternalProjectTemplateEdition from './Configuration/InternalProjects/Templates/InternalProjectTemplateEdition/InternalProjectTemplateEdition';
import InternalProjectTemplates from './Configuration/InternalProjects/Templates/InternalProjectTemplates';
import JoinNetwork from './Configuration/JoinNetwork/JoinNetwork';
import RemoveScholarships from './Configuration/RemoveScholarships/RemoveScholarships';
import OperationKey from './Configuration/Retentions/OperationKey';
import OperationKeysTable from './Configuration/Retentions/OperationKeysTable';
import Retentions from './Configuration/Retentions/Retentions';
import RetentionsTable from './Configuration/Retentions/RetentionsTable';
import TransactionCode from './Configuration/Retentions/TransactionCode';
import TransactionCodesTable from './Configuration/Retentions/TransactionCodesTable';
import SectionMenu from './Configuration/SectionMenu';
import TemplateConfig from './Configuration/Templates/TemplateConfig';
import Template from './Configuration/Templates/Templates';
import CustomFieldsTranslations from './Configuration/Translations/CustomFieldsTranslations';
import MailLOPD from './Configuration/Translations/MailTranslations/MailLOPD';
import MailTranslations from './Configuration/Translations/MailTranslations/MailTranslations';
import MailTranslationsDetails from './Configuration/Translations/MailTranslations/MailTranslationsDetails';
import Translations from './Configuration/Translations/Translations';
import Cookies from './Cookies/Cookies';
import Entities from './Entities/EntitiesTables/Entities';
import EntitiesConfirmed from './Entities/EntitiesTables/EntitiesConfirmed';
import EntitiesCreated from './Entities/EntitiesTables/EntitiesCreated';
import EntitiesRequested from './Entities/EntitiesTables/EntitiesRequested';
import EntityActivation from './Entities/EntityActivation';
import EntityMenu from './Entities/EntityMenu';
import CreateEntityInternal from './Entities/generalData/CreateEntityInternal';
import CreatePreEntity from './Entities/generalData/CreatePreEntity';
import CreateEvent from './Event/CreateEvent/CreateEventNew';
import EventRegistrations from './Event/EventTabs/EventRegistrations';
import EventSuscriptions from './Event/EventTabs/EventSuscriptions';
import EventManagement from './Event/EventTabs/Events';
import UserEvents from './Event/EventTabs/UserEvents';
import EventLogin from './Event/TypeForm/EventLogin';
import EventTypeFormLogin from './Event/TypeForm/EventTypeFormLogin';
import Home from './Home/Home';
import InternalProjectEdition from './InternalProjects/InternalProjectEdition/InternalProjectEdition';
import InternalProjectStudentEvaluation from './InternalProjects/InternalProjectFreeForms/InternalProjectStudentEvaluation';
import InternalProjects from './InternalProjects/InternalProjects';
import InternalProjectsConfigurationFields from './InternalProjects/InternalProjectsConfigurationFields/InternalProjectsConfigurationFields';
import InternalProjectsSettings from './InternalProjects/InternalProjectsSettings/InternalProjectsSettings';
import FinishedInterviewsList from './Interviews/FinishedInterviewsList';
import InterviewCalendar from './Interviews/InterviewCalendar';
import PendingInterviewsList from './Interviews/PendingInterviewsList';
import Lopd from './LOPD/Lopd';
import LegalAdvice from './LegalAdvice/LegalAdvice';
import Login from './Login/Login';
import NewPassword from './NewPassword/NewPassword';
import Alerts from './Notifications/Alerts';
import MessageThread from './Notifications/MessageThread';
import PendingTasks from './Notifications/PendingTasks';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import StyleGuideNC from './Private/StyleGuideNC';
import FormsAssistant from './Project/FormsAssistant/FormsAssistant';
import ProjectFlow from './Project/ProjectFlow';
import MyProjects from './Project/ProjectTabs/MyProjects';
import Projects from './Project/ProjectTabs/Projects';
import Register from './Register/Register';
import RegisterSuccessfull from './Register/RegisterSuccessfull';
import EducadorPending from './RegisterEducator/EducatorPending';
import RegisterEducator from './RegisterEducator/RegisterEducator';
import Scholarship from './Scholarship/Scholarship';
import ScholarshipList from './Scholarship/ScholarshipLists/ScholarhipList';
import StudentCalendar from './Scholarship/StudentCalendar/StudentCalendar';
import ScholarshipFreeTransversalCompetences from './Scholarship/TransversalCompetences/ScholarshipFreeTransversalCompetences';
import SearchResults from './SearchResults/SearchResults';
import CreateService from './Service/CreateService/CreateServiceNew';
import ServiceApplicants from './Service/ServiceTabs/ServiceApplicants';
import ServiceManagement from './Service/ServiceTabs/Services';
import UserServices from './Service/ServiceTabs/UserServices';
import CreateUser from './Users/CreateUser';
import EditUser from './Users/EditUser';
import MyProfile from './Users/MyProfile';
import RegisterData from './Users/RegisterData';
import Users from './Users/Users';
import AuthThroughMail from './Users/authThroughMail';
import Events from './ZingNetwork/Events';
import Services from './ZingNetwork/Services';
import InstituteScholarshipsPayments from './Accounting/ScholarshipsPayment/InstituteScholarshipPayments/InstituteScholarshipsPayments';
import FollowUpSettings from './Configuration/FollowUpSettings/FollowUpSettings';
const routes: AppRoute[] = [];

routes_definition.map(route => {
  switch (route.name) {
    case 'root':
      routes.push({
        ...route,
        component: Login,
      });
      break;
    case 'registro':
      routes.push({
        ...route,
        component: Register,
      });
      break;
    case 'registro-datos':
      routes.push({
        ...route,
        component: RegisterData,
      });
      break;
    case 'responsable-entidad':
      routes.push({
        ...route,
        render: routeProps => <EntityActivation {...routeProps} registerOk />,
      });
      break;
    case 'registro-entidad':
      routes.push({
        ...route,
        render: routeProps => <Register {...routeProps} entity={true} />,
      });
      break;
    case 'solicitud-entidad':
      routes.push({
        ...route,
        render: routeProps => <CreatePreEntity {...routeProps} />,
      });
      break;
    case 'activacion-entidad':
      routes.push({
        ...route,
        component: EntityActivation,
      });
      break;
    case 'acceso':
      routes.push({
        ...route,
        component: Login,
      });
      break;
    case 'restablecer-acceso':
      routes.push({
        ...route,
        component: NewPassword,
      });
      break;
    case 'activar-cuenta':
      routes.push({
        ...route,
        component: RegisterSuccessfull,
      });
      break;
    case 'validar-entidad':
      routes.push({
        ...route,
        render: routeProps => <EntityMenu {...routeProps} validation />,
      });
      break;
    case 'confirmar-entidad':
      routes.push({
        ...route,
        render: routeProps => <EntityMenu {...routeProps} confirmation />,
      });
      break;
    case 'editar-entidad':
      routes.push({
        ...route,
        render: routeProps => <EntityMenu {...routeProps} edit />,
      });
      break;
    case 'detalles-entidad':
      routes.push({
        ...route,
        render: routeProps => <EntityMenu {...routeProps} details />,
      });
      break;
    case 'mi-entidad':
      routes.push({
        ...route,
        render: routeProps => <EntityMenu {...routeProps} myEntity />,
      });
      break;
    case 'home':
      routes.push({
        ...route,
        component: Home,
      });
      break;
    case 'mi-perfil':
      routes.push({
        ...route,
        render: routeProps => <MyProfile {...routeProps} />,
      });
      break;
    case 'detalles-usuario':
      routes.push({
        ...route,
        render: routeProps => <EditUser {...routeProps} readOnly />,
      });
      break;
    case 'usuarios':
      routes.push({
        ...route,
        component: Users,
      });
      break;
    case 'editar-usuario':
      routes.push({
        ...route,
        render: routeProps => <EditUser {...routeProps} readOnly={false} />,
      });
      break;
    case 'crear-usuario':
      routes.push({
        ...route,
        component: CreateUser,
      });
      break;
    case 'reuniones':
      routes.push({
        ...route,
        // component: Home,
      });
      break;
    case 'convenios':
      routes.push({
        ...route,
        // component: Home,
      });
      break;
    case 'tareas-pendientes':
      routes.push({
        ...route,
        component: PendingTasks,
      });
      break;
    case 'alerts':
      routes.push({
        ...route,
        component: Alerts,
      });
      break;
    case 'detalle-mensaje':
      routes.push({
        ...route,
        component: MessageThread,
      });
      break;
    /* case 'kpis':
      routes.push({
        ...route,
        // component: Home,
      });
      break; */
    case 'becas-zing-programme':
      routes.push({
        ...route,
        render: routeProps => <Scholarship {...routeProps} institut={false} />,
      });
      break;
    case 'sobre-nous-cims':
      routes.push({
        ...route,
        // component: Home,
      });
      break;
    case 'encuestas':
      routes.push({
        ...route,
        // component: Home,
      });
      break;

    case 'becas-entidad':
      routes.push({
        ...route,
        render: routeProps => <ScholarshipList {...routeProps} type={'entity'} />,
      });
      break;
    case 'becas-instituto':
      routes.push({
        ...route,
        render: routeProps => <ScholarshipList {...routeProps} type={'institute'} />,
      });
      break;
    case 'becas-escoles-concertades':
      routes.push({
        ...route,
        render: routeProps => <ScholarshipList {...routeProps} type={'study_center'} />,
      });
      break;
    case 'mis-becas':
      routes.push({
        ...route,
        render: routeProps => <ScholarshipList {...routeProps} type={'institute'} myScholarship />,
      });
      break;
    case 'pago-becas-instituto':
      routes.push({
        ...route,
        component: InstituteScholarshipsPayments,
      });
      break;
    case 'pago-becas-entities':
      routes.push({
        ...route,
        component: EntitiesScholarshipPayments,
      });
      break;
    case 'pago-facturas':
      routes.push({
        ...route,
        component: InvoicesPayment,
      });
      break;
    case 'pago-proyectos':
      routes.push({
        ...route,
        component: ProjectPayments,
      });
      break;
    case 'entidades':
      routes.push({
        ...route,
        component: Entities,
      });
      break;
    case 'centros-academicos':
      routes.push({
        ...route,
        component: AcademicCenterList,
      });
      break;
    case 'crear-entidad-nueva':
      routes.push({
        ...route,
        component: CreateEntityInternal,
      });
      break;
    case 'entidades-nuevas':
      routes.push({
        ...route,
        component: EntitiesCreated,
      });
      break;
    case 'entidades-solicitadas':
      routes.push({
        ...route,
        component: EntitiesRequested,
      });
      break;
    case 'entidades-confirmadas':
      routes.push({
        ...route,
        component: EntitiesConfirmed,
      });
      break;
    case 'ayuda':
      routes.push({
        ...route,
        component: Ayuda,
      });
      break;

    case 'solicitudes-entrevista':
      routes.push({
        ...route,
        component: PendingInterviewsList,
      });
      break;
    case 'entrevistas-realizadas':
      routes.push({
        ...route,
        component: FinishedInterviewsList,
      });
      break;
    case 'calendario-entrevistador':
      routes.push({
        ...route,
        component: InterviewCalendar,
      });
      break;
    case 'calendario-becario':
      routes.push({
        ...route,
        component: StudentCalendar,
      });
      break;
    case 'auth-throughMail':
      routes.push({
        ...route,
        render: routeProps => {
          return <AuthThroughMail {...routeProps} />;
        },
      });
      break;
    case 'school-scholarship-detail':
      routes.push({
        ...route,
        render: routeProps => {
          return <Scholarship {...routeProps} detalle institut />;
        },
      });
      break;
    case 'entity-scholarship-detail':
      routes.push({
        ...route,
        render: routeProps => {
          return <Scholarship {...routeProps} detalle institut={false} />;
        },
      });
      break;
    case 'scholarship-edition':
      routes.push({
        ...route,
        render: routeProps => {
          return <Scholarship {...routeProps} institut={false} />;
        },
      });
      break;
    case 'registro-educador':
      routes.push({
        ...route,
        component: RegisterEducator,
      });
      break;
    case 'educador-pendiente':
      routes.push({
        ...route,
        component: EducadorPending,
      });
      break;

    case 'proyectos':
      routes.push({
        ...route,
        component: Projects,
      });
      break;
    case 'mis-proyectos':
      routes.push({
        ...route,
        component: MyProjects,
      });
      break;
    case 'detalle-proyecto-seccion':
    case 'detalle-proyecto':
      routes.push({
        ...route,
        component: ProjectFlow,
      });
      break;
    case 'asistente-formularios':
      routes.push({
        ...route,
        component: FormsAssistant,
      });
      break;
    case 'search':
      routes.push({
        ...route,
        component: SearchResults,
      });
      break;
    case 'about-us-view':
      routes.push({
        ...route,
        component: AboutUsResources,
      });
      break;
    case 'services':
      routes.push({
        ...route,
        component: ServiceManagement,
      });
      break;
    case 'services-usuario':
      routes.push({
        ...route,
        component: UserServices,
      });
      break;

    case 'detalle-servicio':
      routes.push({
        ...route,
        component: CreateService,
      });
      break;

    case 'solicitantes-servicio':
      routes.push({
        ...route,
        component: ServiceApplicants,
      });
      break;

    case 'events':
      routes.push({
        ...route,
        component: EventManagement,
      });
      break;
    case 'eventos-usuario':
      routes.push({
        ...route,
        component: UserEvents,
      });
      break;

    case 'detalle-evento':
      routes.push({
        ...route,
        component: CreateEvent,
      });
      break;

    case 'lopd-terms':
      routes.push({
        ...route,
        component: LOPDForm,
      });
      break;

    case 'suscritos-evento':
      routes.push({
        ...route,
        component: EventSuscriptions,
      });
      break;

    case 'inscritos-evento':
      routes.push({
        ...route,
        component: EventRegistrations,
      });
      break;
    case 'event-login':
      routes.push({
        ...route,
        component: EventLogin,
        anonymousAccess: true,
      });
      break;
    case 'event-typeform-auth':
      routes.push({
        ...route,
        component: EventTypeFormLogin,
      });
      break;
    case 'config-areas':
      routes.push({
        ...route,
        component: AreasConfig,
      });
      break;
    case 'config-about-us-section':
      routes.push({
        ...route,
        component: AboutsUs,
      });
      break;
    case 'about-us-edition':
      routes.push({
        ...route,
        component: AboutUsEdition,
      });
      break;
    case 'about-us-new':
      routes.push({
        ...route,
        render: routeProps => {
          return <AboutUsEdition {...routeProps} createMode />;
        },
      });
      break;
    case 'documents-help':
      routes.push({
        ...route,
        component: AboutUsResources,
      });
      break;
    case 'cookies-policy':
      routes.push({
        ...route,
        component: Cookies,
      });
      break;
    case 'lopd':
      routes.push({
        ...route,
        component: Lopd,
      });
      break;
    case 'privacy-policy':
      routes.push({
        ...route,
        component: PrivacyPolicy,
      });
      break;
    case 'legal-advice':
      routes.push({
        ...route,
        component: LegalAdvice,
      });
      break;
    case 'commercial-communication':
      routes.push({
        ...route,
        component: CommercialCommunications,
      });
      break;
    case 'kpis-config':
      routes.push({
        ...route,
        component: KPISConfig,
      });
      break;
    case 'kpis-creacion':
      routes.push({
        ...route,
        component: KPIS,
      });
      break;
    case 'kpis-edicion':
      routes.push({
        ...route,
        render: routeProps => <KPIS {...routeProps} leftMenu />,
      });
      break;
    case 'kpis-detail':
      routes.push({
        ...route,
        render: routeProps => <KPIS {...routeProps} disabled leftMenu />,
      });
      break;
    case 'indicator-types':
      routes.push({
        ...route,
        component: KPISTypes,
      });
      break;
    case 'assignament':
      routes.push({
        ...route,
        component: Assigment,
      });
      break;
    case 'assignament-type':
      routes.push({
        ...route,
        component: AssignmentType,
      });
      break;
    case 'assignament-sub':
      routes.push({
        ...route,
        component: Assigment,
      });
      break;
    case 'config-fields':
      routes.push({
        ...route,
        component: KPISConfigCustom,
      });
      break;
    case 'style-guide-nc':
      routes.push({
        ...route,
        component: StyleGuideNC,
      });
      break;
    case 'config-retentions':
      routes.push({
        ...route,
        component: RetentionsTable,
      });
      break;
    case 'retentions-transaction-code':
      routes.push({
        ...route,
        component: TransactionCodesTable,
      });
      break;
    case 'retentions-operation-key':
      routes.push({
        ...route,
        component: OperationKeysTable,
      });
      break;
    case 'cargas-masivas':
      routes.push({
        ...route,
        component: MassiveUpload,
      });
      break;
    case 'pending_invoices':
      routes.push({
        ...route,
        component: PendingInvoices,
      });
      break;
    case 'editar-retencion':
      routes.push({
        ...route,
        component: Retentions,
      });
      break;
    case 'nueva-retencion':
      routes.push({
        ...route,
        component: Retentions,
        render: routeProps => <Retentions {...routeProps} create />,
      });
      break;
    case 'editar-transaction-code':
      routes.push({
        ...route,
        component: TransactionCode,
      });
      break;
    case 'nuevo-transaction-code':
      routes.push({
        ...route,
        component: TransactionCode,
        render: routeProps => <TransactionCode {...routeProps} create />,
      });
      break;
    case 'editar-operation-key':
      routes.push({
        ...route,
        component: OperationKey,
      });
      break;
    case 'nueva-operation-key':
      routes.push({
        ...route,
        component: OperationKey,
        render: routeProps => <OperationKey {...routeProps} create />,
      });
      break;
    case 'accounting':
      routes.push({
        ...route,
        component: AccountingMenu,
      });
      break;
    case 'center-cost-create':
      routes.push({ ...route, render: routeProps => <CostCenterConfig {...routeProps} /> });
      break;
    case 'center-cost-edit':
      routes.push({ ...route, render: routeProps => <CostCenterConfig {...routeProps} /> });
      break;
    case 'center-cost':
      routes.push({ ...route, render: routeProps => <SwitchCostCenter {...routeProps} /> });
      break;
    case 'config-enable-scholarship':
      routes.push({ ...route, component: ConfigEnableScholarship });
      break;
    case 'config-followups':
      routes.push({ ...route, component: FollowUpSettings });
      break;
    case 'config-followup-date':
      routes.push({ ...route, component: FollowupDates });
      break;
    case 'config-remove-scholarships':
      routes.push({ ...route, component: RemoveScholarships });
      break;
    case 'config-add-network':
      routes.push({ ...route, component: JoinNetwork });
      break;
    case 'zing-network-events':
      routes.push({
        ...route,
        component: Events,
      });
      break;
    case 'zing-network-services':
      routes.push({
        ...route,
        component: Services,
      });
      break;
    case 'internal-projects':
      routes.push({
        ...route,
        component: InternalProjects,
      });
      break;
    case 'internal-projects-config-date':
      routes.push({
        ...route,
        component: InternalProjectsSettings,
      });
      break;
    case 'internal-projects-config-fields':
      routes.push({
        ...route,
        component: InternalProjectsConfigurationFields,
      });
      break;
    case 'internal-projects-academy':
      routes.push({
        ...route,
        component: InternalProjects,
      });
      break;
    case 'config-templates':
      routes.push({
        ...route,
        component: Template,
      });
      break;
    case 'config-template-create':
      routes.push({
        ...route,
        component: TemplateConfig,
      });
      break;
    case 'config-template-edit':
      routes.push({
        ...route,
        component: TemplateConfig,
      });
      break;
    case 'config-internal-project-templates':
      routes.push({
        ...route,
        component: InternalProjectTemplates,
      });
      break;
    case 'config-internal-project-template-edit':
      routes.push({
        ...route,
        component: InternalProjectTemplateEdition,
      });
      break;
    case 'config-internal-project-template-general-forms-assistant':
      routes.push({
        ...route,
        component: FormsAssistantInternalProjectTemplate,
      });
      break;
    case 'gestion-areas':
    case 'traducciones':
    case 'config-scholarship':
    case 'config-about-us':
    case 'config-menu':
    case 'config-fields-config':
    case 'internal-projects-config':
      routes.push({
        ...route,
        component: SectionMenu,
      });
      break;
    case 'traducciones-aplicativo':
      routes.push({
        ...route,
        component: Translations,
      });
      break;
    case 'lopd-mails':
      routes.push({
        ...route,
        component: MailLOPD,
      });
      break;
    case 'cf-traducciones':
      routes.push({
        ...route,
        component: CustomFieldsTranslations,
      });
      break;
    case 'mailtraducciones':
      routes.push({
        ...route,
        component: MailTranslations,
      });
      break;
    case 'mail-translate-details':
      routes.push({
        ...route,
        component: MailTranslationsDetails,
      });
      break;
    case 'expense-accounts':
      routes.push({
        ...route,
        component: ExpenseAccounts,
      });
      break;
    case 'send-to-sage':
      routes.push({
        ...route,
        component: SendToSAGE,
      });
      break;
    case 'nueva-cuenta-de-gasto':
      routes.push({
        ...route,
        component: ExpenseAccountsConfig,
      });
      break;
    case 'editar-cuenta-de-gasto':
      routes.push({
        ...route,
        component: ExpenseAccountsConfig,
      });
      break;
    case 'detalle-cuenta-de-gasto':
      routes.push({ ...route, render: routeProps => <ExpenseAccountsConfig detailsMode {...routeProps} /> });
      break;
    case 'configuration':
      routes.push({
        ...route,
        component: Configuration,
      });
      break;
    case 'current_invoices':
      routes.push({
        ...route,
        component: InvoicesTable,
      });
      break;
    case 'paid_invoices':
      routes.push({
        ...route,
        render: routeProps => <InvoicesTable paidInvoices {...routeProps} />,
      });
      break;
    case 'invoice-edit':
      routes.push({
        ...route,
        component: InvoiceConfig,
      });
      break;
    case 'academy-detail':
      routes.push({
        ...route,
        component: AcademyLayout,
      });
      break;
    case 'internal-projects-edit':
      routes.push({
        ...route,
        component: InternalProjectEdition,
      });
      break;
    case 'internal-projects-detail':
      routes.push({
        ...route,
        component: InternalProjectEdition,
      });
      break;
    case 'internal-projects-academy-edit':
      routes.push({
        ...route,
        component: InternalProjectEdition,
      });
      break;
    case 'internal-projects-academy-detail':
      routes.push({
        ...route,
        component: InternalProjectEdition,
      });
      break;
    case 'ip-free-evaluation':
      routes.push({
        ...route,
        component: InternalProjectStudentEvaluation,
      });
      break;
    case 'page-404':
      routes.push({
        ...route,
        component: page404,
      });
      break;
    case 'scholarship-auth-transversal-competences':
    case 'scholarship-free-transversal-competences':
      routes.push({
        ...route,
        component: ScholarshipFreeTransversalCompetences,
      });
      break;
  }

  return routes;
});

export default routes;
